import city from '../images/city.png';
import nature from '../images/nature.png';
import window from '../images/window.png';

const cardsProjectsPreview = [
	{
		id: 0,
		status: '23 заявки',
		nameProject: 'Проект с длинным названием про экологию',
		city: 'г. Москва',
		day: '24.10.2023',
		time: '10:00 - 16:00',
		image: nature,
	},
	{
		id: 1,
		status: '23 заявки',
		nameProject: 'Уборка пляжа',
		city: 'г. Москва',
		day: '24.10.2023',
		time: '10:00 - 16:00',
		image: city,
	},
	{
		id: 2,
		status: 'Проект на модерации',
		nameProject: 'Проект с длинным названием про экологию',
		city: 'г. Москва',
		day: '24.10.2023',
		time: '10:00 - 16:00',
		isModeration: true,
		image: window,
	},
	{
		id: 3,
		status: '23 заявки',
		nameProject: 'Уборка пляжа',
		city: 'г. Москва',
		day: '24.10.2023',
		time: '10:00 - 16:00',
		image: nature,
	},
	{
		id: 4,
		status: 'Проект на модерации',
		nameProject: 'Проект с длинным названием про экологию',
		city: 'г. Москва',
		day: '24.10.2023',
		time: '10:00 - 16:00',
		isModeration: true,
		image: window,
	},
	{
		id: 5,
		status: '23 заявки',
		nameProject: 'Уборка пляжа',
		city: 'г. Москва',
		day: '24.10.2023',
		time: '10:00 - 16:00',
		image: nature,
	},
];

export default cardsProjectsPreview;

export const dataNavArray = [
	{
		id: 0,
		label: 'проекты',
		path: '/projects',
		anchor: '',
	},
	{
		id: 1,
		label: 'новости',
		path: '/',
		anchor: 'news',
	},
	{
		id: 2,
		label: 'связаться с нами',
		path: '/',
		anchor: 'request',
	},
];

export const cardsArray = [
	{
		cardId: 1,
		tags: ['#ЛучшеВместе'],
		title:
			'Молодёжь Южного Урала помогает семьям военнослужащих в сёлах и деревнях',
		date: '23.04.23',
	},
	{
		cardId: 2,
		tags: ['#ЛучшеВместе'],
		title:
			'Обновление закона «О благотворительной деятельности и добровольчестве»',
		date: '12.06.23',
	},
	{
		cardId: 3,
		tags: ['#ЛучшеВместе'],
		title:
			'Более 500 уникальных проектов из 95 стран представлены на Международной Премии',
		date: '23.04.23',
	},
	{
		cardId: 4,
		tags: ['#ЛучшеВместе'],
		title:
			'Обновление закона «О благотворительной деятельности и добровольчестве»',
		date: '23.04.23',
	},
	{
		cardId: 5,
		tags: ['#ЛучшеВместе'],
		title:
			'Молодёжь Южного Урала помогает семьям военнослужащих в сёлах и деревнях',
		date: '23.04.23',
	},
];

export const valuesArray = [
	{
		valuesId: 1,
		title: 'Шанс вдохнуть жизнь в свой город:',
		description:
			'Платформа предоставляет волонтерам возможность активно участвовать в реализации городских проектов и инициатив, чтобы оживить и улучшить городскую среду, создавая пространства и услуги, соответствующие ожиданиям жителей.',
	},
	{
		valuesId: 2,
		title: 'Персональный рост и расширение горизонтов:',
		description:
			'Волонтерство на платформе предоставляет уникальную возможность для развития профессиональных и личностных навыков. Вы сможете приобрести новые знания, опыт работы в команде и столкнуться с реальными вызовами, что способствует вашему личному росту и развитию.',
	},
	{
		valuesId: 3,
		title: 'Содействие взаимодействию и взаимоподдержке',
		description:
			' Участие в городских проектах и инициативах помогает вам встретить единомышленников и создать прочные связи в городском сообществе. Вы будете работать рука об руку с другими волонтёрами, чтобы достичь общих целей и создать сильную и взаимодействующую команду.',
	},
	{
		valuesId: 4,
		title: 'Вдохновение к позитивным переменам:',
		description:
			'Ваше участие в проектах и инициативах на платформе будет служить источником вдохновения для других жителей города. Вы станете активным гражданином, оказывая заботу о своем городе, влияя на позитивные изменения и стимулируя других к активности и участию в общественной жизни.',
	},
];
